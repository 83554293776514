import { ModuleWithProviders, NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { NgPreloadService } from "./preload.service";
import { PreloadDirective } from "./preload.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [PreloadDirective],
  exports: [PreloadDirective],
})
export class NgImagePreloadModule {
  static forRoot(): ModuleWithProviders<NgImagePreloadModule> {
    return {
      ngModule: NgImagePreloadModule,
      providers: [NgPreloadService],
    };
  }
}
