import { AlertController } from "@ionic/angular";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(
    public translate: TranslateService,
    public alertController: AlertController,
    private router: Router
  ) {}

  async showAlert(msg) {
    const alert = await this.alertController.create({
      header: this.translate.instant("common.ERROR"),
      message: msg,
      buttons: [
        {
          text: this.translate.instant("common.RETRY"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
      ],
    });
    await alert.present();
  }

  handleError(error) {
    {
      let message = "";
      if (error.status === 500) {
        message = this.translate.instant("common.SERVER_ERROR");
        this.showAlert(message);
      } else if (error.status === 404) {
        message = this.translate.instant("common.CHECK_SERVER");
        this.showAlert(message);
      } else if (error.status === 403) {
        localStorage.removeItem("token");
        this.router.navigateByUrl("/signup");
      } else if (error.status === 400) {
        message = this.translate.instant("common.CHECK_DATA");
        this.showAlert(message);
      } else if (error.status === 409) {
        message = this.translate.instant("common.CHECK_MAIL");
        this.showAlert(message);
      }
    }
  }
}
