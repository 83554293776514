import { BehaviorSubject } from "rxjs";
import { GlobalService } from "../global/global.service";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public selected = "";
  public lng$ = new BehaviorSubject<any>(null);
  public currentLng$ = this.lng$.asObservable();

  constructor(
    private translate: TranslateService,
    private globalService: GlobalService
  ) {}

  setInitialAppLanguage() {
    const language = this.translate.getBrowserLang();
    this.globalService.getKey("SELECTED_LANGUAGE").then((val) => {
      if (val) {
        this.setLanguage(val);
      } else {
        console.log("setInitialAppLanguage", language);
        this.setLanguage(language);
      }
    });
  }

  changeLanguage(data) {
    this.lng$.next(data);
    // this.loadData();
  }

  getLanguages() {
    return [
      // {text: 'English', value: 'en' },
      { text: "Français", value: "fr" },
      { text: "عربي", value: "ar" },
    ];
  }

  setLanguage(lng) {
    document.body.className = lng;
    if (lng === "ar") {
      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";
    }
    this.translate.use(lng);
    this.selected = lng;
    console.log("setLanguage", lng);
    this.globalService.setKey("SELECTED_LANGUAGE", lng);
  }
}
