import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

import { NgPreloadService } from "./preload.service";

@Directive({
  selector: "[ngPreload]",
})
export class PreloadDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private service: NgPreloadService
  ) {}

  @Input()
  fallback: string;

  @Input("ngPreload")
  set preload(src: string) {
    if (src) {
      this.service.addImage(this.el.nativeElement, {
        src,
        background: this.el.nativeElement.nodeName !== "IMG",
        fallback: this.fallback,
      });
    }
  }
}
