import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "../../../providers/commonService/common-service.service";
import { DomSanitizer } from "@angular/platform-browser";
import { GlobalService } from "../../../providers/global/global.service";
import { HttpService } from "../../../providers/httpService/http.service";
import { StaticDataService } from "../../../providers/staticData/static-data.service";
import { TranslateFromApiService } from "../../../providers/translate/translate-from-api.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, forkJoin, interval } from "rxjs";
import * as moment from "moment";
import {
  AlertController,
  MenuController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";

@Component({
  selector: 'app-poll-modal',
  templateUrl: './poll-modal.page.html',
  styleUrls: ['./poll-modal.page.scss'],
})
export class PollModalPage implements OnInit {
  
  publishedDate: any;
  polls;
  quizzes;
  type = 'polls';
  gameBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
  };
  pollBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    idPollType: 1,
  };

  pollResponse = {
    pollId: null,
    vote: 0,
    responseTime: null,
  };
  public pollFinishied: any = [];

  public secondsToDday: any
  public minutesToDday: any
  public hoursToDday: any
  public daysToDday: any
  public isClicked: boolean = false;

  public poll;
  public dailyPoll: any = [];

  public classInterval;
  public gameId;
  public pollList: any = [];
  public voteExist: boolean = false;
  public duration: any = null;
  public displayQuestions: any = [];
  public score = 0;
  public pollInterval = interval(1000);
  public awaitSendVote = false;
  constructor(
    public staticDataService: StaticDataService,
    public globalService: GlobalService,
    private sanitizer: DomSanitizer,
    private httpService: HttpService,
    private translateFromApiService: TranslateFromApiService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    private router: Router,
    public modalController: ModalController,

) { }

  ngOnInit() {
    this.loadData()
  }

  async loadData() {
    // this.poll = null;
    // await this.globalService.showLoader(
    //   this.translate.instant("common.LOADING")
    // );

    const reqPolls = await this.httpService.post("polls", this.pollBody);
    forkJoin([reqPolls]).subscribe(
      async (data: any) => {
        const results = data[0];

        console.log("results", results, data);
        const todayDate = this.formatDate(new Date());
        const pollsVoteArray = [];

        results.forEach((poll) => {
          if (poll.vote.length > 0) {
            poll.vote.forEach((vote) => {
              if (vote.createdAt.split("T")[0] === todayDate) {
                // this.hasVoteedToday = true;
                pollsVoteArray.push(poll);
              }
            });
          }
        });
        console.log(pollsVoteArray, "pollsVoteArray after ");

        let pollsTochangeindexs = results.slice(0);
        for (let index = 0; index < pollsTochangeindexs.length; index++) {
          pollsTochangeindexs = this.move(
            pollsTochangeindexs,
            index,
            Math.floor(Math.random() * (0 + pollsTochangeindexs.length))
          );
        }
        console.log(
          "results bug treted pollsVoteArray",
          pollsTochangeindexs
        );

        if (pollsVoteArray.length === 3) {
          pollsVoteArray.forEach((element) => {
            if (element.vote[element.vote.length - 1].isCorrect) {
              this.score +=
                element.vote[element.vote.length - 1].responseTime + 10;
            }
          });
          this.globalService.dismissLoader();
          this.globalService.presentOldGameAlert(this.score);
          this.score = 0;
          return;
        } else if (pollsVoteArray.length === 0) {
          pollsTochangeindexs = pollsTochangeindexs.splice(0, 3);
          this.displayQuestions = pollsTochangeindexs;
        } else {
          let arrayToConcate = [];
          this.displayQuestions = pollsVoteArray;
          const hasNotFinishQuisesAndRemainder = 3 - pollsVoteArray.length;
          arrayToConcate = arrayToConcate.concat(pollsVoteArray);
          arrayToConcate = arrayToConcate.concat(
            pollsTochangeindexs.splice(0, hasNotFinishQuisesAndRemainder)
          );
          this.displayQuestions = arrayToConcate;
          pollsVoteArray.forEach((element) => {
            if (element.vote[element.vote.length - 1].isCorrect) {
              this.score +=
                element.vote[element.vote.length - 1].responseTime + 10;
            }
          });
        }
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.displayQuestions.length; i++) {
          this.displayQuestions[i].choices = [];
          for (let j = 1; j < 8; j++) {
            if (this.displayQuestions[i]["choice" + j]) {
              this.displayQuestions[i].choices.push({
                index: j,
                choice: this.displayQuestions[i]["choice" + j],
                choiceAr: this.displayQuestions[i]["choice" + j + "Ar"],
                choiceFr: this.displayQuestions[i]["choice" + j + "Fr"],
                picture: this.displayQuestions[i]["choice" + j + "Picture"],
                count: this.displayQuestions[i]["choice" + j + "Count"],
              });
            }
          }
        }

        await this.globalService
          .getKey("SELECTED_LANGUAGE")
          .then((lngLocal: string) => {
            this.pollList = this.translateFromApiService.translate(
              lngLocal,
              this.displayQuestions
            );
          });

        this.poll = this.pollList[0];
        console.log("this.pollList", this.poll.picture);

        if (this.poll.vote != undefined) {
          if (this.poll.vote.length > 0) {
            if (typeof this.poll.vote[0].vote !== "undefined") {
              this.poll.choices.map((choice) => {
                this.pollResponse.vote = parseInt(this.poll.vote[0].vote, 10);
              });
              this.voteExist = true;
            }
          }
        }

        this.calcDateDiff();
        // await this.globalService.dismissLoader();
        this.classInterval = setInterval(() => {
          if (!this.awaitSendVote) {
            this.calcDateDiff();

          }
        }, 1000);
      },
      async (error) => {
        if (error.status === 401) {
          this.globalService.showSessionExpiredAlertAndLogOut();
        }        // await this.globalService.dismissLoader();
        this.commonService.handleError(error);
      }
    );
  }

  formatDate(date) {
    // tslint:disable-next-line:prefer-const
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      // tslint:disable-next-line:prefer-const
      year = d.getFullYear();

    // tslint:disable-next-line:curly
    if (month.length < 2) month = "0" + month;
    // tslint:disable-next-line:curly
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  move(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length;
      while (k-- + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }
  calcDateDiff() {
    if (this.poll !== undefined) {
      const endDay: Date = new Date(moment(this.poll.endPublishing).format())
      const dDay = endDay.valueOf()

      const milliSecondsInASecond = 1000
      const hoursInADay = 24
      const minutesInAnHour = 60
      const secondsInAMinute = 60

      const timeDifference = dDay - Date.now()

      this.daysToDday = Math.floor(
        timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
      )
      this.hoursToDday = Math.floor(
        (timeDifference /
          (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
        hoursInADay
      )
      this.minutesToDday = Math.floor(
        (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute
      )
      this.secondsToDday =
        Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute
    }
  }

  vote() {
    this.modalController.dismiss();

    this.router.navigateByUrl("/app/tabs/game/poll");

  }
}
