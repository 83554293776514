import { GlobalService } from "../global/global.service";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
@Injectable({
  providedIn: "root",
})
export class TranslateFromApiService {
  constructor() {}

  translate(language, obj) {
    if (typeof language === "undefined" || language === null) {
      return obj;
    }
    const lngLocal = language[0].toUpperCase() + language[1];
    if (Array.isArray(obj)) {
      let i = null;
      for (i of Object.keys(obj)) {
        obj[i] = this.translate(language, obj[i]);
      }
      return obj;
    } else {
      for (const i in obj) {
        if (obj[i] === null || typeof obj[i] === "string") {
          const key = i + lngLocal;
          if (typeof obj[key] !== "undefined") {
            if (obj[key] !== null) {
              obj[i] = obj[key];
            }
          }
        } else if (Array.isArray(obj[i])) {
          for (const j of Object.keys(obj[i])) {
            obj[i][j] = this.translate(language, obj[i][j]);
          }
        } else if (typeof obj[i] === "object" && obj[i] !== null) {
          obj[i] = this.translate(language, obj[i]);
        }
      }
      return obj;
    }
  }
}
