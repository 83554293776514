import { Component, OnInit } from "@angular/core";

import { LanguageService } from "../../providers/languages/language.service";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-languages-popover",
  templateUrl: "./languages-popover.page.html",
  styleUrls: ["./languages-popover.page.scss"],
})
export class LanguagesPopoverPage implements OnInit {
  languages = [];
  selected = "";

  constructor(
    private popoverCtrl: PopoverController,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.languages = this.languageService.getLanguages();
    this.selected = this.languageService.selected;
  }

  selectLanguage(lng) {
    this.languageService.setLanguage(lng);
    this.popoverCtrl.dismiss(lng);
  }
}
