// import { BehaviorSubject } from 'rxjs';
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  PickerController,
  ToastController,
} from "@ionic/angular";
import { NavigationEnd, Router } from "@angular/router";

import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { TranslateService } from "@ngx-translate/core";
import { UserData } from "../user-data";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  private loader: HTMLIonLoadingElement;
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  public navigationSubscription = null;

  constructor(
    public loadingController: LoadingController,
    private readonly storage: Storage,
    public toastController: ToastController,
    public alertController: AlertController,
    private userData: UserData,
    public router: Router,
    public modalController: ModalController,
    private readonly menu: MenuController,
    public translate: TranslateService
  ) {
    this.currentUrl = this.router.url;
    console.log("this.currentUrl ", this.currentUrl);
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    console.log("this.previousUrl", this.previousUrl);

    return this.previousUrl;
  }

  async showLoader(messageShowing: string) {
    this.loader = await this.loadingController.create({
      message: messageShowing,
      translucent: false,
      cssClass: "circular-loader",
    });
    await this.loader.present();
  }

  async dismissLoader() {
    if (this.loader) {
      await this.loader.dismiss();
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      // tslint:disable-next-line:object-literal-shorthand
      message: message,
      duration: 800,
    });
    toast.present();
  }

  async presentWelcomeToast(message: string) {
    const toast = await this.toastController.create({
      // tslint:disable-next-line:object-literal-shorthand
      message: message,
      duration: 800,
    });
    await this.menu.enable(true);
    toast.present();
  }

  async presentAlert(alertMessage: string, buttonMessage: string) {
    const alert = await this.alertController.create({
      // header: alertHeader,
      // subHeader: alertSubHeader,
      message: alertMessage,
      buttons: [buttonMessage],
    });

    await alert.present();
  }

  async presentSimpleAlert(alertMessage: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant("common.ERROR"),
      message: alertMessage,
      buttons: [this.translate.instant("common.OK")],
    });

    await alert.present();
  }

  async presentGameAlert(score) {
    const alert = await this.alertController.create({
      cssClass: "quiz-result",
      message:
        `<img src="/assets/img/coupe.png" style="height:150px" />` +
        "<br/>" +
        this.translate.instant("games.YOURSCOREIS") +
        " " +
        score +
        this.translate.instant("games.POINTS"),
      // header: this.translate.instant('games.YOURSCOREIS') + score + this.translate.instant('games.POINTS'),
      buttons: [
        {
          text: this.translate.instant("common.OK"),
          handler: () => {
            this.router.navigateByUrl("/app/tabs/game");
          },
        },
      ],
    });

    await alert.present();
  }

  async presentOldGameAlert(score) {
    const alert = await this.alertController.create({
      header: this.translate.instant("games.ALREADYANSWEARED"),
      message:
        this.translate.instant("games.YOURSCOREIS") +
        " " +
        score +
        " " +
        this.translate.instant("games.POINTINYORSOLDE"),
      buttons: [
        {
          text: this.translate.instant("common.OK"),
          handler: () => {
            this.router.navigateByUrl("/app/tabs/game");
          },
        },
      ],
    });

    await alert.present();
  }

  async showSessionExpiredAlertAndLogOut() {
    this.userData.logout().then(async () => {
      await this.menu.enable(false);
      return this.router.navigateByUrl("");
    });
    // const alert = await this.alertController.create({
    //   header: this.translate.instant('common.ERROR'),
    //   message: this.translate.instant('common.SESSIONEXPIRED'),
    //   buttons: [
    //     {
    //       text: this.translate.instant('common.OK'),
    //       handler: () => {
    //         // this.userData.logout().then(async () => {
    //         //  await this.menu.enable(false);
    //         //  return this.router.navigateByUrl('');
    //         // });
    //         // this.resultsService.clearInterval();
    //       }
    //     }
    //   ]
    // });
    // await alert.present();
  }

  convertTime(secondsInput: number) {
    const sec: number = secondsInput;
    let hours, minutes, seconds: number;
    let hoursStr, minutesStr, secondsStr: string;

    hours = Math.floor(((sec % 31536000) % 86400) / 3600);
    hoursStr = hours <= 9 ? "0" + hours.toString() : hours.toString();

    minutes = Math.floor((((sec % 31536000) % 86400) % 3600) / 60);
    minutesStr = minutes <= 9 ? "0" + minutes.toString() : minutes.toString();

    seconds = Math.floor(((sec % 31536000) % 86400) % 3600) % 60;
    secondsStr = seconds <= 9 ? "0" + seconds.toString() : seconds.toString();

    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }

  getKey(key: string) {
    return this.storage.get(key);
  }

  removeKey(key: string) {
    return this.storage.remove(key);
  }

  setKey(name: string, value: any) {
    try {
      return this.storage.set(name, value);
    } catch (e) {
      return null;
    }
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
