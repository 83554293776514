import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

import { Injectable } from "@angular/core";
// import { NetworkService } from '../network/network.service';
import { Network } from "@ionic-native/network/ngx";
import { Observable } from "rxjs";
import { StaticDataService } from "../staticData/static-data.service";
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  connectionStatus: boolean;
  constructor(
    private readonly storage: Storage,
    private readonly httpClient: HttpClient,
    private readonly staticDataService: StaticDataService,
    // public networkService: NetworkService,
    public network: Network
  ) {
    // this.networkService.currentNetwork$.subscribe(async (stateNetwork) => {
    //   console.log('stateNetwork', stateNetwork);
    // });
  }

  async setHeaders() {
    const options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + (await this.storage.get("token")),
      }),
    };
    return options;
  }

  async post(serviceName: string, data: any) {
    // this.networkService.currentNetwork$.subscribe(async (stateNetwork) => {
    //   console.log('stateNetwork', stateNetwork);
    //   const options = await this.setHeaders();
    //   const lng = await this.storage.get('SELECTED_LANGUAGE');

    //   if (stateNetwork === 'offline') {
    //     const data$ = new Observable((observer) => {
    //       observer.error(new Error('No Internet Connection !!!'));
    //       observer.complete();
    //     });
    //     return data$;
    //   } else if (stateNetwork === 'online') {
    //     // tslint:disable-next-line:no-shadowed-variable

    //     return this.httpClient.post(
    //       this.staticDataService.settings.server +
    //         lng +
    //         '/' +
    //         this.staticDataService.settings.apiURL +
    //         this.staticDataService.settings.apiVersion +
    //         serviceName,
    //       data,
    //       options
    //     );
    //   }

    //   // return data$;
    // });
    console.log("omg internet ", window.navigator.onLine);

    if (!window.navigator.onLine) {
      console.log("we dont have internet ");
      // if there is no internet, throw a HttpErrorResponse error
      // since an error is thrown, the function will terminate here
      return Observable.throw(
        new HttpErrorResponse({ error: "Internet is required." })
      );
    } else {
      console.log("we have internet ");

      const options = await this.setHeaders();
      const lng = await this.storage.get("SELECTED_LANGUAGE");
      return this.httpClient.post(
        this.staticDataService.settings.server +
          lng +
          "/" +
          this.staticDataService.settings.apiURL +
          this.staticDataService.settings.apiVersion +
          serviceName,
        data,
        options
      );
    }
  }

  async get(serviceName: string) {
    const options = await this.setHeaders();
    const lng = await this.storage.get("SELECTED_LANGUAGE");
    return this.httpClient.get(
      this.staticDataService.settings.server +
        lng +
        "/" +
        this.staticDataService.settings.apiURL +
        this.staticDataService.settings.apiVersion +
        serviceName,
      options
    );
  }

  async put(serviceName: string, data: any) {
    const options = await this.setHeaders();
    const lng = await this.storage.get("SELECTED_LANGUAGE");
    return this.httpClient.put(
      this.staticDataService.settings.server +
        lng +
        "/" +
        this.staticDataService.settings.apiURL +
        this.staticDataService.settings.apiVersion +
        serviceName,
      data,
      options
    );
  }
  async putuser(serviceName: string) {
    const options = await this.setHeaders();

    const lng = await this.storage.get("SELECTED_LANGUAGE");
    return this.httpClient.get(
      this.staticDataService.settings.server +
      lng +
      "/" +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      serviceName,
      options

    );
  }
  async delete(serviceName: string, data: any) {
    const options = await this.setHeaders();
    const lng = await this.storage.get("SELECTED_LANGUAGE");
    return this.httpClient.delete(
      this.staticDataService.settings.server +
        lng +
        "/" +
        this.staticDataService.settings.apiURL +
        this.staticDataService.settings.apiVersion +
        serviceName,
      options
    );
  }
}
