import { RouterModule, Routes } from "@angular/router";

import { LanguagesPopoverPage } from "./languages-popover.page";
import { NgModule } from "@angular/core";

const routes: Routes = [
  {
    path: "",
    component: LanguagesPopoverPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LanguagesPopoverPageRoutingModule {}
