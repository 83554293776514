import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { LanguagesPopoverPage } from "./languages-popover.page";
import { LanguagesPopoverPageRoutingModule } from "./languages-popover-routing.module";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LanguagesPopoverPageRoutingModule,
  ],
  declarations: [LanguagesPopoverPage],
})
export class LanguagesPopoverPageModule {}
