import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { NgImagePreloadModule } from "../../../../../directives/ng-image-preload/preload.module";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { VideoPlayerPage } from "./video-player.page";
import { VideoPlayerPageRoutingModule } from "./video-player-routing.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    VideoPlayerPageRoutingModule,
    TranslateModule,
    NgImagePreloadModule.forRoot(),
  ],
  declarations: [VideoPlayerPage],
})
export class VideoPlayerPageModule {}
